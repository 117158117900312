import fetchConfiguracoes from '@services/fetch-configuracoes';
import { useEffect, useState } from 'react';

export type ConfiguracoesProps = {
    empresaId: string;
    netlifySiteId: string;
    idGoogleAnalytics: string;
    idGoogleTagManager: string;
    idFacebookPixel: string;
    utilizaSimulacao: string;
    urlLogoMarca: string;
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    textoDestaque: string;
    urlFacebook: string;
    urlYoutube: string;
    urlInstagram: string;
    urlX: string;
    urlIcone: string;
    fonteMenuColor: string;
    urlBackgroundTopo: string;
    backgroundColorTopo: string;
    tipoOperacao: number;
    banners: BannersProps[];
}
export type BannersProps = {
    configuracoesSiteId: string;
    urlBanner: string;
    linkBanner: string;
    id: string;
}


export default function useConfiguracoes() {
    const [configuracoes, setConfiguracoes] = useState<ConfiguracoesProps>();

    useEffect(() => {
        async function getConfiguracoes() {
            try {
                const request = await fetchConfiguracoes();
                setConfiguracoes(request);
            } catch (error) {
                console.error(error);
            }
        }

        getConfiguracoes();
    }, []);

    return {
        configuracoes,
    };
}
